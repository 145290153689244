
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup() {
    const qr = ref<null | HTMLElement>(null)
    const flag = ref(false)
    const enter = () => {
      flag.value = true
    }
    const leave = () => {
      flag.value = false
    }
    return {
      qr,
      enter,
      leave,
      flag
    }
  }
})
