<template>
  <div class="policy_list_content base_width">
    <div class="home_main_content_head_bg" ref="RefBg"/>
    <div class="home_main_content_content flex">
      <div class="left_part">
        <TodayPolicy :todayPoliciesList="todayPolicy" />
        <PolicyListTips />
        <PolicyListContentHall />
      </div>
      <div class="right_part">
        <RecommendPolicies :recommendList="recommendList" @toDetail="toDetail"/>
        <EndingPolicies :endingPoliciesList="endingPoliciesList"/>
        <HotPolicy :hotList="hotList"/>
      </div>
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import { dailyPolicy, selectByIndex, abortList, heat } from '@/api/policyList'
import { ItodayPolicyItem, IrecommendPolicy, IendingList, IhotList } from '@/types/policyList/index'
import TodayPolicy from '@/common/TodayPolicy.vue'
import RecommendPolicies from '@/common/recommendPolicies.vue'
import EndingPolicies from '@/common/endingPolicies.vue'
import PolicyListTips from './PolicyListContentTips.vue'
import PolicyListContentHall from './PolicyListContentHall.vue'
import HotPolicy from '@/common/hotList.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    activeIndex: {
      type: Number,
      required: true
    }
  },
  components: {
    TodayPolicy,
    RecommendPolicies,
    PolicyListTips,
    PolicyListContentHall,
    EndingPolicies,
    HotPolicy
  },
  setup (props) {
    const bg2 = require('@/assets/images/policyList/policyListBg2.png')
    const bg3 = require('@/assets/images/policyList/policyListBg3.png')
    const router = useRouter()
    const RefBg = ref<null | HTMLElement>(null)
    // 今日慧报
    const todayPolicy = ref<ItodayPolicyItem[]>([])
    const getDailyPolicy = () => {
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
      const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
      const time = `${year}-${month}-${day}`
      dailyPolicy({ date: time }).then(res => {
        todayPolicy.value = res.data.declarationData
      }).catch((err) => {
        console.log(err)
      })
    }
    // 推荐政策
    const recommendList = ref<IrecommendPolicy[]>([])
    const getRecommend = () => {
      selectByIndex({}).then(res => {
        recommendList.value = res.data
      })
    }
    const toDetail = (pid: string) => {
      router.push({ path: '/detail', query: { pid } })
    }
    // 截止政策
    const endingPoliciesList = ref<IendingList[]>([])
    const getEndingList = () => {
      abortList({
        paginate: {
          currentPageNum: '1',
          recordsPerPage: '5'
        }
      }).then(res => {
        endingPoliciesList.value = res.data.rows
      })
    }
    // 政策热榜
    const hotList = ref<IhotList[]>([])
    const getHotList = () => {
      heat({
        type: '2'
      }).then(res => {
        hotList.value = res.data
      })
    }
    watch(() => props.activeIndex, (newV) => {
      if (RefBg.value) {
        if (newV === 1) {
          RefBg.value.style.background = `url(${bg3})`
        } else {
          RefBg.value.style.background = `url(${bg2})`
        }
      }
    })
    onBeforeMount(() => {
      getDailyPolicy()
      getRecommend()
      getEndingList()
      getHotList()
    })
    return {
      todayPolicy,
      recommendList,
      endingPoliciesList,
      hotList,
      toDetail,
      RefBg
    }
  }
})
</script>
<style lang='scss' scoped>
.policy_list_content {
  margin-top: -105px;
  .home_main_content_head_bg {
    height: 191px;
    width: 100%;
    background: url(~assets/images/policyList/policyListBg2.png) no-repeat
      center center;
    background-size: cover;
    box-shadow: 0px 6px 48px rgba(0, 0, 0, 0.04);
    opacity: 1;
    border-radius: 8px;
  }
  .home_main_content_content {
    margin-top: 50px;
    .left_part {
      margin-right: 60px;
      min-width: 860px;
    }
    .right_part {
      min-width: 280px;
      flex: 1;
    }
  }
}
</style>
