<template>
  <div id="endingPolicies">
    <policiesTitle title="即将截止" />
    <div class="line" />
    <ul>
      <li
        v-for="(item, index) in endingPoliciesList"
        :key="index"
        class="pointer"
        @click.prevent="toDetail(item.pid)"
      >
        <div class="content">
          <div class="content_title" ref="RefpolicyName">
            {{ item.policy_name }}
          </div>
          <div class="tips">{{ item.short_name }}</div>
        </div>
        <div class="endTime">
          <span>倒计时</span>
          <div class="time" v-if="item.remaining_days && item.remaining_days !=='0'">{{ item.remaining_days }}天</div>
          <div class="time" v-else style="color:#FF5B60">今日截止</div>
        </div>
      </li>
    </ul>
    <!-- <div class="btn pointer" @click="endAction">查看更多</div> -->
  </div>
</template>

<script>
import policiesTitle from '@/common/policiesTitle'

export default {
  components: {
    policiesTitle
  },
  props: {
    endingPoliciesList: {
      default: () => [],
      type: Array
    }
  },
  methods: {
    endAction() {
      this.$emit('endAction')
    },
    toDetail(pid) {
      this.$router.push({ path: '/detail', query: { pid } })
    }
  }
}
</script>
<style lang="scss" scoped>
#endingPolicies {
  margin-top: 51px;
  .title {
    margin-bottom: 24px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f1f1f1;
    margin-bottom: 26px;
  }
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .content {
      .content_title {
        font-size: 14px;
        max-width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        color: #555555;
      }
      .tips {
        margin-top: 9px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .endTime {
      // flex: 1;
    }
  }
  li:last-of-type {
    margin-bottom: 0;
  }
  .endTime {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    padding-bottom: 7px;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #c8a260;
      padding: 2px 11px 5px 11px;
      background: #fffbf2;
      margin-bottom: 9px;
    }
    .time {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 17px;
      color: #c8a260;
      width: 100%;
      text-align: center;
    }
  }
  .btn {
    margin-top: 29px;
    height: 46px;
    width: 100%;
    line-height: 46px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
li:hover .content_title {
  color: #c8a260 !important;
}
</style>
