
// import {
//   policySearch
// } from '@/api/searchResult'
import { defineComponent, onMounted, ref } from 'vue'
// import BaseDropDown from '@/common/BaseDropDown.vue'
import TodayPolicy from '@/common/TodayPolicy.vue'
import { searchLabel, policySearch } from '@/api/searchResult'
import {
  IareaList,
  IlabelList,
  ItodayPolicyItem
} from '@/types/policyList/index'
// import { searchByBigData } from '@/api/policyList'
import pagination from '@/common/pagination.vue'
import CommonTabs from '@/common/CommonTabs/index.vue'
import CommonTabsItem from '@/common/CommonTabs/CommonTabsItem/index.vue'
import CheckLine from '@/page/searchResult/components/checkLine.vue'

interface Ipageination {
  total?: number
  currentPage: number
  pageSize: number
}

export default defineComponent({
  components: {
    // BaseDropDown,
    CommonTabs,
    CommonTabsItem,
    TodayPolicy,
    pagination,
    CheckLine
  },
  setup() {
    // 下拉框
    const areaList = ref<IareaList[]>([])
    const labelList = ref<IlabelList[]>([])
    const indLabelList = ref<any[]>([])
    const zcCheckList = ref<Array<number>>([])
    const typeCheckList = ref<Array<number | string>>([])
    const productCheckList = ref<Array<number | string>>([])
    const getLabel = () => {
      searchLabel().then((res) => {
        if (res.data) {
          areaList.value = res.data.area_list
          labelList.value = res.data.label_list
          indLabelList.value = res.data.ind_label_list
        }
      })
    }
    // 政策大厅
    const subActiveName = ref('1')

    // 政策大厅
    const todayPoliciesList = ref<ItodayPolicyItem[]>([])
    const page = ref(1)
    const areaId = ref('')
    const code = ref('')
    const pageSize = ref(8)
    const total = ref(0)
    const getPolicyList = () => {
      let typeCheckListValue = typeCheckList.value
      let productCheckListValue = productCheckList.value
      if (subActiveName.value !== '1') {
        typeCheckListValue = []
        productCheckListValue = []
      }
      const classCode = [...typeCheckListValue, ...productCheckListValue]
        .filter((item) => item)
        .join(',')
      const params = Object.assign(
        {},
        { keyword: '' },
        {
          areaId: zcCheckList.value.join(','),
          classCode: classCode,
          class: subActiveName.value
        },
        {
          paginate: {
            currentPageNum: page.value,
            recordsPerPage: pageSize.value
          }
        }
      )
      policySearch(params).then((res) => {
        total.value = res.data.total
        console.log(res.data.data)
        todayPoliciesList.value = res.data.data
      })
    }
    const toggle = (value: IareaList | IlabelList) => {
      console.log(value)
      if (value.code) {
        code.value = value.code
      } else {
        areaId.value = String(value.area_id)
      }
      page.value = 1
      getPolicyList()
    }
    // 分页
    const Hall = ref<null | HTMLElement>(null)
    const scrollH = ref(0)
    const search = (value: Ipageination) => {
      page.value = value.currentPage
      pageSize.value = value.pageSize
      getPolicyList()
      window.scrollTo(0, scrollH.value)
    }
    const parentCb = (el: HTMLElement, height: number): void | number => {
      if (el.offsetParent) {
        height += el.offsetTop
        return parentCb(el.offsetParent as HTMLElement, height)
      } else {
        return height - 50
      }
    }
    onMounted(() => {
      getLabel()
      getPolicyList()
      if (Hall.value) {
        scrollH.value = parentCb(Hall.value, 0) as number
      }
    })
    return {
      labelList,
      areaList,
      indLabelList,
      todayPoliciesList,
      total,
      toggle,
      page,
      pageSize,
      search,
      Hall,
      subActiveName,
      zcCheckList,
      typeCheckList,
      productCheckList
    }
  }
})
