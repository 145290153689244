<template>
  <div class="policyListHall" ref="Hall">
    <div class="policiesHall_title">政策大厅</div>
    <div class="policiesHall_head">
      <!-- <BaseDropDown
        :datalist="areaList"
        basePickUpItem="政策区域"
        keyValue="area_id"
        value="org_area"
        @toggle="toggle"
      />
      <BaseDropDown
        :datalist="labelList"
        basePickUpItem="政策类别"
        keyValue="code"
        value="name"
        @toggle="toggle"
      /> -->
      <div class="policiesHall_head-ct">
        <div class="sR-ct_topTitle">
          <common-tabs v-model="subActiveName" @change="toggle">
            <common-tabs-item active-name="1">
              <span class="sR-ct_topTxt">申报通知</span>
            </common-tabs-item>
            <common-tabs-item active-name="2">
              <span class="sR-ct_topTxt">公示公告</span>
            </common-tabs-item>
            <common-tabs-item active-name="3">
              <span class="sR-ct_topTxt">政策法规</span>
            </common-tabs-item>
          </common-tabs>
        </div>
        <div class="sR-searchParams">
          <div class="sR-searchParams_ct">
            <check-line
              v-if="subActiveName === '1'"
              :list="labelList"
              v-model="typeCheckList"
              header="项目类别"
              true-string="code"
              key-string="name"
              @check-change="toggle"
            ></check-line>
            <check-line
              v-if="subActiveName === '1'"
              :list="indLabelList"
              v-model="productCheckList"
              header="产业类别"
              true-string="code"
              key-string="name"
              @check-change="toggle"
            ></check-line>
            <check-line
              :list="areaList"
              v-model="zcCheckList"
              header="发布地区"
              true-string="area_id"
              key-string="org_area"
              @check-change="toggle"
            ></check-line>
          </div>
        </div>
      </div>
    </div>
    <div class="policiesHall_content">
      <TodayPolicy
        form-policies-hall
        :today-policies-list="todayPoliciesList"
      />
      <pagination :total="total" @search="search" v-model:currentPage="page" />
    </div>
  </div>
</template>
<script lang='ts'>
// import {
//   policySearch
// } from '@/api/searchResult'
import { defineComponent, onMounted, ref } from 'vue'
// import BaseDropDown from '@/common/BaseDropDown.vue'
import TodayPolicy from '@/common/TodayPolicy.vue'
import { searchLabel, policySearch } from '@/api/searchResult'
import {
  IareaList,
  IlabelList,
  ItodayPolicyItem
} from '@/types/policyList/index'
// import { searchByBigData } from '@/api/policyList'
import pagination from '@/common/pagination.vue'
import CommonTabs from '@/common/CommonTabs/index.vue'
import CommonTabsItem from '@/common/CommonTabs/CommonTabsItem/index.vue'
import CheckLine from '@/page/searchResult/components/checkLine.vue'

interface Ipageination {
  total?: number
  currentPage: number
  pageSize: number
}

export default defineComponent({
  components: {
    // BaseDropDown,
    CommonTabs,
    CommonTabsItem,
    TodayPolicy,
    pagination,
    CheckLine
  },
  setup() {
    // 下拉框
    const areaList = ref<IareaList[]>([])
    const labelList = ref<IlabelList[]>([])
    const indLabelList = ref<any[]>([])
    const zcCheckList = ref<Array<number>>([])
    const typeCheckList = ref<Array<number | string>>([])
    const productCheckList = ref<Array<number | string>>([])
    const getLabel = () => {
      searchLabel().then((res) => {
        if (res.data) {
          areaList.value = res.data.area_list
          labelList.value = res.data.label_list
          indLabelList.value = res.data.ind_label_list
        }
      })
    }
    // 政策大厅
    const subActiveName = ref('1')

    // 政策大厅
    const todayPoliciesList = ref<ItodayPolicyItem[]>([])
    const page = ref(1)
    const areaId = ref('')
    const code = ref('')
    const pageSize = ref(8)
    const total = ref(0)
    const getPolicyList = () => {
      let typeCheckListValue = typeCheckList.value
      let productCheckListValue = productCheckList.value
      if (subActiveName.value !== '1') {
        typeCheckListValue = []
        productCheckListValue = []
      }
      const classCode = [...typeCheckListValue, ...productCheckListValue]
        .filter((item) => item)
        .join(',')
      const params = Object.assign(
        {},
        { keyword: '' },
        {
          areaId: zcCheckList.value.join(','),
          classCode: classCode,
          class: subActiveName.value
        },
        {
          paginate: {
            currentPageNum: page.value,
            recordsPerPage: pageSize.value
          }
        }
      )
      policySearch(params).then((res) => {
        total.value = res.data.total
        console.log(res.data.data)
        todayPoliciesList.value = res.data.data
      })
    }
    const toggle = (value: IareaList | IlabelList) => {
      console.log(value)
      if (value.code) {
        code.value = value.code
      } else {
        areaId.value = String(value.area_id)
      }
      page.value = 1
      getPolicyList()
    }
    // 分页
    const Hall = ref<null | HTMLElement>(null)
    const scrollH = ref(0)
    const search = (value: Ipageination) => {
      page.value = value.currentPage
      pageSize.value = value.pageSize
      getPolicyList()
      window.scrollTo(0, scrollH.value)
    }
    const parentCb = (el: HTMLElement, height: number): void | number => {
      if (el.offsetParent) {
        height += el.offsetTop
        return parentCb(el.offsetParent as HTMLElement, height)
      } else {
        return height - 50
      }
    }
    onMounted(() => {
      getLabel()
      getPolicyList()
      if (Hall.value) {
        scrollH.value = parentCb(Hall.value, 0) as number
      }
    })
    return {
      labelList,
      areaList,
      indLabelList,
      todayPoliciesList,
      total,
      toggle,
      page,
      pageSize,
      search,
      Hall,
      subActiveName,
      zcCheckList,
      typeCheckList,
      productCheckList
    }
  }
})
</script>
<style lang='scss' scoped>
.policyListHall {
  margin-top: 50px;
  min-height: 1450px;
  .policiesHall_title {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  .policiesHall_head {
    display: flex;
    #dropDown {
      margin-right: 10px;
    }
    #dropDown:last-of-type {
      margin-right: 0;
    }
  }
}
:deep(.el-pagination) {
  margin-top: 50px;
  text-align: center;
  .btn-prev {
    width: 30px;
    height: 30px;
    max-width: 30px;
  }
  li {
    // width: 30px;
    padding: 0 10px;
    height: 30px !important;
    line-height: 30px !important;
    background: #3c89ff;
    text-align: center;
    margin-right: 10px;
  }
  .btn-next {
    width: 30px;
    height: 30px;
  }
}
.sR-ct_topTitle {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: left;
  padding: 0;
  border-bottom: 1px solid #f1f1f1;
  .sR-ct_topTxt {
    display: inline-block;
    min-width: 88px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    cursor: pointer;
    position: relative;
    // &.isActive {
    //   // color: #3c89ff;

    //   // &::after {
    //   //   content: '';
    //   //   width: 100%;
    //   //   height: 3px;
    //   //   position: absolute;
    //   //   bottom: -1px;
    //   //   left: 0;
    //   //   background-color: #3c89ff;
    //   // }
    // }
  }
}
.sR-searchParams {
  width: 100%;
  padding: 32px 0px 23px 0;
  margin-bottom: 43px;
  border-bottom: 1px solid #f1f1f1;
  position: relative;
}
.policiesHall_head-ct {
  width: 100%;
  flex: 0 0 auto;
}
</style>
