<template>
  <div id="todayPolicies" :style="{ 'margin-top': marginTop }">
    <template v-if="todayPoliciesList.length > 0 && !formPoliciesHall">
      <div class="title">
        今日慧报
        更新<span>{{ todayPoliciesList.length }}</span>条
      </div>
      <div class="line" />
    </template>
    <ul>
      <template v-if="sTodayPoliciesList.length > 0">
        <baseItem
          v-for="(item, index) in sTodayPoliciesList"
          :key="index"
          :item-list="item"
          @toDetail="toDetail"
        />
      </template>
      <template v-else-if="sTodayPoliciesList.length === 0 && formPoliciesHall">
        <li style="color:#909399;text-align:center;line-height:20px">暂无数据</li>
      </template>
    </ul>
  </div>
</template>

<script>
import baseItem from './baseItem'

export default {
  components: {
    baseItem
  },
  props: {
    todayPoliciesList: {
      type: Array,
      default: () => []
    },
    formPoliciesHall: {
      default: false,
      type: Boolean
    },
    // 距离顶部高度
    marginTop: {
      default: '24px',
      type: String
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  },

  computed: {
    sTodayPoliciesList () {
      if (!this.limit) {
        return this.todayPoliciesList
      }
      return this.todayPoliciesList.slice(0, this.limit)
    }
  },

  methods: {
    toDetail (pid, t) {
      this.$router.push({ path: '/detail', query: { pid, type: t } })
    }
  }
}

</script>
<style lang='scss' scoped>
#todayPolicies {
  .title {
    margin-bottom: 24px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    span {
      color: #C8A260;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f1f1f1;
    opacity: 1;
    margin-bottom: 21px;
  }
}
</style>
