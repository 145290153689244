<template>
  <section>
    <div class="download_aside">
      <div
        class="banner_left"
        :class="{
          banner_left_close: isShow,
          banner_left_open: isClose,
          active: isLeftClose
        }"
      >
        <img
          src="@/assets/images/common/bannerBottom/banner_left.png"
          @click="show"
          alt=""
        />
        <div class="closeIcon" @click="leftClose"></div>
      </div>
      <div
        class="banner_bottom"
        :class="{ banner_bottom_open: isShow, banner_bottom_close: isClose }"
      >
        <div class="close" @click="close">
          <img src="@/assets/images/common/bannerBottom/close.png" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {
  defineComponent,
  ref
} from 'vue'
export default defineComponent({
  setup() {
    const isClose = ref<boolean>(false)
    const isShow = ref<boolean>(false)
    const isLeftClose = ref<boolean>(false)
    const close = () => {
      isClose.value = true
      isShow.value = false
    }
    const show = () => {
      isClose.value = false
      isShow.value = true
    }
    const leftClose = () => {
      isLeftClose.value = true
    }
    return {
      isClose,
      isShow,
      isLeftClose,
      close,
      show,
      leftClose
    }
  }
})
</script>

<style lang="scss" scoped>
.banner_bottom_close {
  animation: banner_bottom_close 0.3s ease;
  animation-fill-mode: both;
}
.banner_bottom_open {
  animation: banner_bottom_open 0.3s ease;
  animation-fill-mode: both;
  animation-delay: 0.3s;
}
.banner_left_close {
  animation: banner_left_close 0.1s ease;
  animation-fill-mode: both;
}
.banner_left_open {
  animation: banner_left_open 0.3s ease;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  &:hover {
    left: 0 !important;
  }
}
@keyframes banner_bottom_close {
  0% {
    left: 0;
    bottom: 0;
  }
  100% {
    left: -100%;
    bottom: 0;
  }
}
@keyframes banner_bottom_open {
  0% {
    left: -100%;
    bottom: 0;
  }
  100% {
    left: 0;
    bottom: 0;
  }
}
@keyframes banner_left_close {
  0% {
    // left: -12px;
  }
  100% {
    left: -79px;
  }
}
@keyframes banner_left_open {
  0% {
    left: -79px;
  }
  100% {
    left: -12px;
  }
}
.download_aside {
  .banner_bottom {
    position: fixed;
    text-align: center;
    width: 100%;
    height: 188px;
    z-index: 20000;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.14, 0.68, 0.46, 1);
    background: url('~assets/images/common/bannerBottom/banner_bottom.png')
      no-repeat;
    background-size: auto 188px;
    background-position: center;
    left: -100%;
    bottom: 0;
    .close {
      width: 43px;
      height: 43px;
      position: absolute;
      z-index: 20001;
      right: 32px;
      bottom: 32px;
      img {
        width: 43px;
        height: 43px;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .banner_left {
    position: fixed;
    left: -12px;
    bottom: 48px;
    cursor: pointer;
    z-index: 200;
    width: 80px;
    transition: left 0.3s cubic-bezier(0.14, 0.68, 0.46, 1);
    &:hover {
      left: 0;
    }
  }
  .active {
    display: none !important;
  }
  .closeIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
  }
}
</style>
