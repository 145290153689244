import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 企业详情
export function entInfo(data?: RequestData) {
  return request({
    url: '/pla/user/entInfo',
    method: 'post',
    data
  })
}
// 企业认证
export function verify(data?: RequestData) {
  return request({
    url: '/pla/ent/verify',
    method: 'post',
    data
  })
}

// 是否精准匹配
export function isDoMatch(data?: RequestData) {
  return request({
    url: '/pla/mgr/exactMatch/isDoMatch',
    method: 'post',
    data
  })
}

// 精准匹配——新增
export function add(data?: RequestData) {
  return request({
    url: '/pla/mgr/exactMatch/add',
    method: 'post',
    data
  })
}
