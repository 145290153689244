<template>
  <div id="policyList" class="base_min_width flex_col">
    <div class="head_bg base_min_width">
      <div class="head_mask base_width">
        <div class="head_content flex_col">
          <span>企业政策 资金补贴</span>
          <div class="placeHolder"></div>
          <span>完善企业信息，匹配更精准的补贴政策</span>
        </div>
        <div class="math_module_block base_width">
          <Base-tips top="96px" right="37px" />
          <div class="match_module">
            <div class="match_content">
              <ul class="flex">
                <li
                  v-for="(item, index) in matchList"
                  :key="index"
                  @click="activeIndex = index"
                  @mouseenter="activeIndex = index"
                >
                  <span
                    :class="{
                      span_active: activeIndex === index,
                      pointer: true
                    }"
                    >{{ item }}</span
                  >
                  <div class="line"></div>
                </li>
                <div class="line_active" ref="line"></div>
              </ul>
              <template v-if="activeIndex === 0">
                <BaseGetInfoInput
                  v-model:socialCreditCode="socialCreditCode"
                  v-model:entName="entName"
                />
                <div
                  class="match_btn base_btn btn_click pointer"
                  @click="match"
                >
                  极速匹配
                </div>
              </template>
              <template v-else>
                <div
                  class="preciseMatch_btn base_btn btn_click pointer"
                  @click="preciseMatch"
                >
                  立即精准匹配
                </div>
                <span class="precise_content"
                  >完善企业信息，匹配更精准的补贴政策</span
                >
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main class="base_min_width">
      <policyListContent :activeIndex="activeIndex"/>
    </main>
    <footer class="base_min_width flex_col">
      <span>还有其他项目疑问？专家一对一解答！</span>
      <span>提高申报成功率</span>
      <div class="btn pointer" @click="show">点击免费咨询</div>
    </footer>
  </div>
  <bottomBanner/>
</template>
<script lang='ts'>
import { defineComponent, onMounted, ref, watch } from 'vue'
import BaseTips from '@/common/BaseTips.vue'
import policyListContent from './components/PolicyListContent.vue'
import BaseGetInfoInput from '@/common/BaseGetInfoInput.vue'
import bottomBanner from '@/common/bottomBanner.vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import store from '@/store/index'
import { isDoMatch } from '@/api/preciseMatch'

export default defineComponent({
  components: {
    BaseTips,
    policyListContent,
    BaseGetInfoInput,
    bottomBanner
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const entName = ref('')
    const socialCreditCode = ref('')
    const activeIndex = ref(0)
    const isPreciseMatch = ref('')
    const matchList = ref(['极速匹配', '精准匹配'])
    const line = ref<null | HTMLElement>(null)
    const match = () => {
      if (!store.getters.loginStatus) {
        router.push('/login?showTips=1&redirect=' + route.fullPath)
      } else if (!socialCreditCode.value) {
        ElMessage.error('请输入正确的企业名称')
      } else {
        router.push({
          path: '/quickMatch',
          query: { socialCreditCode: socialCreditCode.value }
        })
      }
    }
    const doMatch = () => {
      isDoMatch().then(res => {
        isPreciseMatch.value = res.data.isDoMatch
      })
    }
    const preciseMatch = () => {
      if (!store.getters.loginStatus) {
        router.push('/login?showTips=1&redirect=' + route.fullPath)
      } else {
        if (isPreciseMatch.value === 'YES') {
          router.push({
            path: '/perfectInfoResult'
            // path: '/perfectInfo'
          })
        } else {
          router.push({
            path: '/perfectInfo'
          })
        }
      }
    }
    const show = () => {
      store.commit('changeSideBarOnlineStatus', true)
    }
    onMounted(() => {
      if (store.getters.loginStatus) {
        doMatch()
      }
    })
    watch(activeIndex, (newVal) => {
      if (newVal === 0 && line.value) {
        line.value.style.transform = 'translateX(0)'
      } else if (line.value && newVal === 1) {
        line.value.style.transform = 'translateX(146px)'
      }
    })
    return {
      entName,
      socialCreditCode,
      activeIndex,
      matchList,
      match,
      line,
      preciseMatch,
      show
    }
  }
})
</script>
<style lang='scss' scoped>
.head_bg {
  width: 100%;
  height: 699px;
  background-size: cover;
  background: url(~assets/images/policyList/policyList_bg.jpg) no-repeat center
    center;
  background-size: cover;
  .head_mask {
    position: relative;
    margin: auto;
    .head_content {
      position: absolute;
      top: 215px;
      & > :nth-child(1) {
        font-size: 59px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 51px;
        color: #ffffff;
      }
      & > :nth-child(2) {
        margin-top: 33px;
        width: 92px;
        height: 7px;
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.2) 100%
        );
        opacity: 1;
      }
      & > :last-child {
        margin-top: 38px;
        font-size: 20px;
        font-weight: 400;
        line-height: 41px;
        color: #ffffff;
      }
    }
    .head_title {
      position: absolute;
      left: 0;
      top: 116px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 59px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 51px;
        color: #ffffff;
      }
      .tips {
        margin-top: 38px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 41px;
        color: #ffffff;
      }
    }
    .match_module {
      position: absolute;
      right: 0px;
      top: 151px;
      width: 352px;
      height: 420px;
      z-index: 9;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      .match_content {
        text-align: center;
        // padding: 69px 30px 125px 30px;
        padding: 53px 30px 136px 30px;
        ul {
          position: relative;
        }
        ul,
        li {
          flex: 1;
          .line {
            height: 0px;
            border: 1px solid #707070;
          }
        }
        .line_active {
          position: absolute;
          bottom: 0;
          width: 146px;
          height: 3px;
          background: linear-gradient(90deg, #f4d1b1 0%, #cda97a 100%);
          transition: all 0.3s ease;
        }
        span {
          font-size: 18px;
          font-weight: bold;
          line-height: 41px;
          color: #fff;
        }
        .span_active {
          color: #e1be97;
        }
        .bg {
          position: absolute;
          width: 100%;
          height: 21px;
          background: #499dff;
          opacity: 0.11;
          top: 30px;
        }
        input {
          background: #ffffff;
          border-radius: 2px;
          padding: 18px 71px;
          width: 100%;
          font-size: 16px;
          border: 1px solid #eaeaea;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        }
        input::placeholder {
          font-weight: 400;
          color: #666666;
        }
        .match_btn {
          margin-top: 31px;
          width: 100%;
          opacity: 1;
          border-radius: 5px;
          padding: 15px 0;
          background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
          border-radius: 28px;
          font-size: 20px;
          font-weight: bold;
          color: #230a01;
        }
      }
    }
  }
}
main {
  margin: auto;
}
footer {
  margin-top: 63px;
  width: 100%;
  height: 397px;
  background: url(~assets/images/policyList/policyList_bg3.png) no-repeat center
    center;
  background-size: cover;
  text-align: center;
  padding-top: 86px;
  align-items: center;
  span {
    font-size: 32px;
    font-weight: 400;
    color: #ffffff;
  }
  span:last-of-type {
    margin-top: 15px;
  }
  .btn {
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    margin-top: 32px;
    padding: 22px 93px;
    white-space: nowrap;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
  }
}

:deep(.el-autocomplete) {
  margin-top: 54px;
  width: 100%;
  .el-input__inner {
    height: 56px;
    border-radius: 28px;
    background: #fff;
    opacity: 1;
    padding-left: 34px;
    font-size: 16px;
  }
  input::-webkit-input-placeholder {
    color: #666;
    font-size: 16px;
    font-weight: 400;
  }
}
.preciseMatch_btn {
  margin-top: 70px;
  width: 100%;
  opacity: 1;
  border-radius: 5px;
  padding: 15px 0;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  border-radius: 28px;
  font-size: 20px;
  font-weight: bold;
  color: #230a01;
}
.precise_content {
  display: block;
  font-size: 14px !important;
  font-weight: 400;
  color: #ffffff;
  margin-top: 24px !important;
  white-space: nowrap !important;
}
</style>
