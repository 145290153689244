
import {
  defineComponent,
  ref
} from 'vue'
export default defineComponent({
  setup() {
    const isClose = ref<boolean>(false)
    const isShow = ref<boolean>(false)
    const isLeftClose = ref<boolean>(false)
    const close = () => {
      isClose.value = true
      isShow.value = false
    }
    const show = () => {
      isClose.value = false
      isShow.value = true
    }
    const leftClose = () => {
      isLeftClose.value = true
    }
    return {
      isClose,
      isShow,
      isLeftClose,
      close,
      show,
      leftClose
    }
  }
})
