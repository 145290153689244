<template>
  <div class="policyList_tips flex">
    <div class="content flex_col">
      <span>政策订阅,即时推送</span>
      <span>如订阅关键词相关政策，有更新将对您进行短信通知。</span>
    </div>
    <div class="btnQr" @mouseenter="enter" @mouseleave="leave">订阅政策</div>
    <div class="imgBox flex_col queue-bottom" v-show="flag">
      <img src="@/assets/images/common/qr/wx.png" />
      <span>打开微信扫一扫，订阅政策更方便</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup() {
    const qr = ref<null | HTMLElement>(null)
    const flag = ref(false)
    const enter = () => {
      flag.value = true
    }
    const leave = () => {
      flag.value = false
    }
    return {
      qr,
      enter,
      leave,
      flag
    }
  }
})
</script>
<style lang="scss" scoped>
.policyList_tips {
  margin-top: 50px;
  background: #f5f8ff;
  border: 1px solid #e6eeff;
  border-radius: 3px;
  padding: 38px 41px 38px 31px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .content {
    & > :nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    & > :last-child {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  img:first-of-type {
    width: 261px;
    height: 56px;
  }
  & > .imgBox {
    position: absolute;
    width: 320px;
    height: 315px;
    padding: 68px 0 70px 0;
    background: #ffffff;
    box-shadow: 0px -3px 80px rgba(0, 0, 0, 0.07);
    opacity: 1;
    border-radius: 20px;
    right: -10px;
    top: 95px;
    align-items: center;
    z-index: 10;
    img {
      width: 130px;
      height: 130px;
    }
    span {
      margin-top: 30px;
    }
  }
}
.btnQr {
  width: 189px;
  height: 56px;
  background: #0056ff;
  box-shadow: 0px 0px 10px rgba(0, 86, 255, 0.4);
  line-height: 56px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
</style>
