
import { defineComponent, onMounted, ref, watch } from 'vue'
import BaseTips from '@/common/BaseTips.vue'
import policyListContent from './components/PolicyListContent.vue'
import BaseGetInfoInput from '@/common/BaseGetInfoInput.vue'
import bottomBanner from '@/common/bottomBanner.vue'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import store from '@/store/index'
import { isDoMatch } from '@/api/preciseMatch'

export default defineComponent({
  components: {
    BaseTips,
    policyListContent,
    BaseGetInfoInput,
    bottomBanner
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const entName = ref('')
    const socialCreditCode = ref('')
    const activeIndex = ref(0)
    const isPreciseMatch = ref('')
    const matchList = ref(['极速匹配', '精准匹配'])
    const line = ref<null | HTMLElement>(null)
    const match = () => {
      if (!store.getters.loginStatus) {
        router.push('/login?showTips=1&redirect=' + route.fullPath)
      } else if (!socialCreditCode.value) {
        ElMessage.error('请输入正确的企业名称')
      } else {
        router.push({
          path: '/quickMatch',
          query: { socialCreditCode: socialCreditCode.value }
        })
      }
    }
    const doMatch = () => {
      isDoMatch().then(res => {
        isPreciseMatch.value = res.data.isDoMatch
      })
    }
    const preciseMatch = () => {
      if (!store.getters.loginStatus) {
        router.push('/login?showTips=1&redirect=' + route.fullPath)
      } else {
        if (isPreciseMatch.value === 'YES') {
          router.push({
            path: '/perfectInfoResult'
            // path: '/perfectInfo'
          })
        } else {
          router.push({
            path: '/perfectInfo'
          })
        }
      }
    }
    const show = () => {
      store.commit('changeSideBarOnlineStatus', true)
    }
    onMounted(() => {
      if (store.getters.loginStatus) {
        doMatch()
      }
    })
    watch(activeIndex, (newVal) => {
      if (newVal === 0 && line.value) {
        line.value.style.transform = 'translateX(0)'
      } else if (line.value && newVal === 1) {
        line.value.style.transform = 'translateX(146px)'
      }
    })
    return {
      entName,
      socialCreditCode,
      activeIndex,
      matchList,
      match,
      line,
      preciseMatch,
      show
    }
  }
})
